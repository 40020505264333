
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOwnerProperty } from "@/models/OwnerProperties";
import { IRoute, IExternalRoute } from "@/models/IRoute";
import api from "@/api";
import { PropertyStatusCategory } from "@/enums/StatusCategory";
import OwnerPropertyCardContent from "@/components/OwnerPropertyCardContent.vue";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import OwnerLeadsShimmer from "@/components/OwnerLeadsShimmer.vue";
import { IPropertyStatusCategory } from "@/models/PropertyStatusCategory";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";

@Component({
  name: "OwnerPropertyCard",
  components: {
    OwnerPropertyCardContent,
    HousfyLink,
    OwnerLeadsShimmer,
  },
})
export default class OwnerPropertyCard extends Vue {
  @Prop({ type: Object, default: () => ({} as IOwnerProperty) })
  property!: IOwnerProperty;
  @Prop({ type: String, required: true })
  dataQa!: string;
  @Prop({ type: Number, required: true })
  index!: number;

  isCardClickable = false;
  showCards = false;
  propertyStatus?: IPropertyStatusCategory;

  get propertyLink(): IExternalRoute | IRoute {
    switch (this.propertyStatus?.propertyStatusCategory) {
      case PropertyStatusCategory.PENDING_AD:
      case PropertyStatusCategory.PUBLISHED_AD: {
        const url = MYHExternalRoutes.dashboardProperty(
          this.property.propertyUuid
        );
        return { ...url, path: `${url.path}?old` };
      }
      default:
        return {
          name: "property",
          params: {
            propertyUuid: this.property.propertyUuid,
          },
        };
    }
  }

  async mounted() {
    this.propertyStatus = await api
      .propertyStatusCategory()
      .get(this.property.propertyUuid);
    if (
      this.propertyStatus?.propertyStatusCategory !==
      PropertyStatusCategory.OLD_MYH
    )
      this.isCardClickable = true;
    window.dispatchEvent(new CustomEvent("cardsMounted"));
    this.showCards = true;
  }
}
